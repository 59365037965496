<template>
  <div class="membership">
    
    <div class="membership-title">
      福利一<span>以下会员权益每月任选2</span>
    </div>
    <div class="membership-content1">
      <div v-for="item,index in exchangeList2" :key="index" class="membership-content1-item"
        :class="{'opcity' : exchangedStatus() || isItemExchanged(item.item_name)}">
        <div class="item-icon">
          <img class="item-icon-img" :src="item.item_icon" alt="">
        </div>
        <div class="item-text">
          <div class="item-text-name">{{ item.item_name }}</div>
          <div class="item-text-btn have" v-if="isItemExchanged(item.item_name)">
            已领取
          </div>
          <div v-else class="item-text-btn" @click="exchangedStatus() ? null :confirmClick(item) ">
            立即领取
          </div>
        </div>
      </div>
    </div>
    <div class="membership-title">
      福利二<span>5元话费券</span>
    </div>
    <div class="membership-content2" v-for="item,index in exchangeList1" :key="index">
      <div class="membership-content2-left">
        <img :src="item.item_icon" alt="">
      </div>
      <div class="membership-content2-right">
        <div class="right-name">{{ item.item_name }}</div>
        <div class="right-tip">{{item.item_note ? item.item_note : "单笔支付金额≥5.01元可用" }}</div>
      </div>
     
      <div class="membership-content2-btn have" v-if="isItemExchanged(item.item_name)">
        已领取
      </div>
      <div v-else class="membership-content2-btn"  @click="confirmClick(item)">
        立即领取
      </div>
    </div>

    <div class="membership-title" v-if="exchangeList3.length>0">
      福利三<span>以下会员权益每30天任选1</span>
    </div>
    <div class="membership-content3" v-if="exchangeList3.length>0">
      <div v-for="item,index in exchangeList3" :key="index" class="membership-content3-item"
        :class="{'opcity' : exchangedStatusThree() || isItemExchanged(item.item_name)}">
        <div class="item-icon">
          <img class="item-icon-img" :src="item.item_icon" alt="">
        </div>
        <div class="item-text">
          <div class="item-text-name">{{ item.item_name }}</div>
          <div class="item-text-note">{{ item.item_note }}</div>
          <div class="item-text-btn have" v-if="isItemExchanged(item.item_name)">
            已领取
          </div>
          <div v-else class="item-text-btn" @click="exchangedStatusThree() ? null :confirmClick(item) ">
            立即领取
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { equityList } from "@/api/taiyue/receive.js";
import { mapGetters ,mapActions} from "vuex";

export default {
  components: {
  },
  data() {
    return {
      alert: {
        visibility: false,
        title: "使用说明",
        message: "",
      },
      exchangeList:[],
      exchangeList1:[],
      exchangeList2:[],
      exchangeList3:[],
      list: [],
      title: "",
      vipName: "",
      useRules:{},
      row:{}
    };
  },
  computed: {
    ...mapGetters(["taiyue_token", "taiyue_user_info"]),
  },
  created() {
    const { vipName = "" } = this.$route.query;
    this.vipName = vipName;
    this["taiyue/getUserInfo"]();
    this.getList();
  },
  methods: {
    ...mapActions(["taiyue/getUserInfo"]),
    async getList() {
      console.log(this.taiyue_user_info.exchanged_items[this.vipName])
      this.exchangeList1 = [];
		  this.exchangeList2 = [];
      this.exchangeList3 = [];
      this.$toast.loading({
          duration: 0,
          forbidClick: true,
          message: "加载中...",
        });
      const res = await equityList({ item_require: this.vipName });
      if (res.code == 0) {
				this.exchangeList = res.item;
        if(this.vipName == "视听甄选会员"){
          for (var i = 0; i < this.exchangeList.length; i++) {
            if (this.exchangeList[i].item_exchange_type == "n选1") {
              this.exchangeList1.push(this.exchangeList[i])
            }
            if (this.exchangeList[i].item_exchange_type == "n选2") {
              this.exchangeList2.push(this.exchangeList[i])
            }
          }
        }else if(this.vipName == "优生活会员"){
          for (var i = 0; i < this.exchangeList.length; i++) {
            if (this.exchangeList[i].item_exchange_type == "n选1") {
              this.exchangeList3.push(this.exchangeList[i])
            }
            if (this.exchangeList[i].item_exchange_type == "n选2") {
              this.exchangeList2.push(this.exchangeList[i])
            }
            if (this.exchangeList[i].item_exchange_type == "n") {
              this.exchangeList1.push(this.exchangeList[i])
            }
          }
        }
				this.$toast.clear();
			}else{
				
			}
    },
    confirmClick(item){
      this.$router.push({
        path: "/fz_taiyue/receive/confirmreceive",
        query: {
          item: JSON.stringify(item),
          vipName: this.vipName,
        },
      });
	  },
    // 控制福利一的可领取状态
    exchangedStatus() {
        const data = this.taiyue_user_info.exchanged_items[this.vipName] || [];
        if(this.vipName == "视听甄选会员"){
          if(data.includes('5元话费券')){
            return data.length >= 3;
          }else{
            return data.length >= 2;
          }
        }else if(this.vipName == "优生活会员"){
          if(data.includes('20元生活缴费券')){
            if(this.exchangedStatusThree()){
              return data.length >= 7;
            }else{
              return data.length >= 4;
            }
          }else{
            if(this.exchangedStatusThree()){
              return data.length >= 5;
            }else{
              return data.length >= 2;
            }
          }
        }
        
    },
    isItemExchanged(itemName){
      const data = this.taiyue_user_info.exchanged_items[this.vipName] || [];
		  return data.includes(itemName);
	  },
    // 控制福利三的可领取状态
    exchangedStatusThree() {
      const data = this.taiyue_user_info.exchanged_items[this.vipName] || [];
      try {
        const itemNames = this.exchangeList3.map(item => item.item_name);
        const duplicates = data.filter(item => itemNames.includes(item));
        return duplicates.length >= 1;
        // if(this.exchangeList3.length && data.length){
        //     for(var i =0 ; i<=this.exchangeList3.length;i++){
        //       if(data.includes(this.exchangeList3[i].item_name)){
        //         return true
        //       }
        //     }
        // }
      } catch (error) {
        
      }
      // return data.includes("30元天猫超市") || data.includes("30元盒马生鲜")|| data.includes("30元永辉超市");
    },
    tocheck(){
      this.visibility = false;
      this.$router.push("/carOwner_zhengxing/receive/record")
    },
  },
};
</script>

<style lang="scss" scoped>
.membership {
  // box-sizing: border-box;
  padding: 30px;
  .membership-title{
    width: 100%;
    height: 50px;
    color: #333333;
    font-size: 30px;
    line-height: 50px;
    margin: 20px 0;
    span{
      color: #999999;
      font-size: 26px;
      margin-left: 20px;
    }
  }
  .membership-content1{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    .membership-content1-item{
      // width: 210px;
      flex-basis: 32%;
      height: 294px;
      background: #FFFFFF;
      box-shadow: 0px 5px 25px 0px rgba(0,0,0,0.06);
      border-radius: 8px;
      .item-icon{
        width: 176px;
        height: 126px;
        background: #F6F7FA;
        border-radius: 8px;
        margin: 17px auto 0;
        display: flex;
        justify-content: space-around;
        .item-icon-img{
          width: 86px;
          height: 86px;
          margin: 20px auto 0;
        }
      }
      .item-text{
        width: 176px;
        height: 126px;
        border-radius: 8px;
        margin: 17px auto 0;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        .item-text-name{
          font-size: 28px;
          color: #333333;
        }
        .item-text-btn{
          width: 146px;
          height: 46px;
          background: #FFEEE6;
          border-radius: 23px;
          text-align: center;
          line-height: 46px;
          font-size: 24px;
          color: #FF5000;
        }
        .have{
          background-color: rgba(177, 177, 177, 0.5);
          color: #fff;
        }
      }
    }
    .opcity{
      opacity: 0.4;
    }
    .membership-content1-item:nth-child(3n-1) {
			margin: 0 2% 30px;
		}
  }
  .membership-content2{
    width: 686px;
    height: 160px;
    background: #FFFFFF;
    box-shadow: 0px 5px 25px 0px rgba(0,0,0,0.06);
    border-radius: 8px;
    margin: 0 auto;
    padding: 17px;
    box-sizing: border-box;
    .membership-content2-left{
      width: 126px;
      height: 126px;
      background: #F6F7FA;
      border-radius: 8px;
      float: left;
      img{
        width: 98px;
        height: 67px;
        display: block;
        margin: 30px auto;
      }
    }
    .membership-content2-right{
      width: 360px;
      height: 126px;
      border-radius: 8px;
      float: left;
      color: #333;
      font-size: 32px;
      margin-left: 20px;
      .right-name{
        margin: 20px 0 10px 0;
      }
      .right-tip{
        color: #999999;
        font-size: 24px;
      }
    }
    .membership-content2-btn{
      width: 146px;
      height: 46px;
      background: #FFEEE6;
      border-radius: 31px;
      float: right;
      font-size: 24px;
      color: #FF5000;
      line-height: 46px;
      text-align: center;
      margin-top: 40px;
    }
    .have{
      background-color: rgba(177, 177, 177, 0.5);
      color: #fff;
    }
  }
  .membership-content3{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    .membership-content3-item{
      // width: 210px;
      flex-basis: 32%;
      height: 320px;
      background: #FFFFFF;
      box-shadow: 0px 5px 25px 0px rgba(0,0,0,0.06);
      border-radius: 8px;
      .item-icon{
        width: 176px;
        height: 126px;
        background: #F6F7FA;
        border-radius: 8px;
        margin: 17px auto 0;
        display: flex;
        justify-content: space-around;
        .item-icon-img{
          width: 86px;
          height: 86px;
          margin: 20px auto 0;
        }
      }
      .item-text{
        width: 176px;
        height: 126px;
        border-radius: 8px;
        margin: 17px auto 0;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        .item-text-name{
          font-size: 28px;
          color: #333333;
        }
        .item-text-note{
          font-size: 22px;
          color: #999999;
          margin: 10px 0; 
        }
        .item-text-btn{
          width: 146px;
          height: 46px;
          background: #FFEEE6;
          border-radius: 23px;
          text-align: center;
          line-height: 46px;
          font-size: 24px;
          color: #FF5000;
        }
        .have{
          background-color: rgba(177, 177, 177, 0.5);
          color: #fff;
        }
      }
    }
    .opcity{
      opacity: 0.4;
    }
    .membership-content3-item:nth-child(3n-1) {
			margin: 0 2% 30px;
		}
  }
}
</style>
